import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import {
    Button,
    createTheme,
    FormControl,
    Grid,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    outlinedInputClasses,
    Paper,
    Select,
    ThemeProvider,
    Typography,
    useTheme
} from "@mui/material";
import getLocale, { getUiDirection } from "../helper/Locale";
import '../style/TrackForm.css';
import CustomTextField from "./CustomTextField";
import countriesData from "../i18n/countries.json"

export const customTextFieldTheme = (outerTheme, borderColor = '#E0E3E7', hoverColor = '#B2BAC2', focusedColor = '#6F7E8C') =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '--TextField-brandBorderColor': borderColor,
                        '--TextField-brandBorderHoverColor': hoverColor,
                        '--TextField-brandBorderFocusedColor': focusedColor,
                        backgroundColor: "rgb(232, 241, 250)",
                        borderTopRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                        '& label.Mui-focused': {
                            color: 'var(--TextField-brandBorderFocusedColor)',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: 'var(--TextField-brandBorderHoverColor)',
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: 'var(--TextField-brandBorderFocusedColor)',
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: "rgb(232, 241, 250)",
                        [`&.Mui-focused, &:hover, &:active`]: {
                            backgroundColor: "rgb(232, 241, 250)"
                        },
                        '&:before, &:after': {
                            borderBottom: '2px solid var(--TextField-brandBorderColor)',
                        },
                        '&:hover:not(.Mui-disabled, .Mui-error):before': {
                            borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                        },
                        '&.Mui-focused:after': {
                            borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        '&:before': {
                            borderBottom: '2px solid var(--TextField-brandBorderColor)',
                        },
                        '&:hover:not(.Mui-disabled, .Mui-error):before': {
                            borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                        },
                        '&.Mui-focused:after': {
                            borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                        },
                    },
                },
            },
        },
    });

const TrackForm = () => {
    const outerTheme = useTheme();
    const { t, i18n } = useTranslation();
    const { theme } = useSelector((store) => store.theme);

    const uiDirection = getUiDirection();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const orgParam = params.get('org') ?? [];
    const locParam = params.get('loc') ?? [];
    const byParam = params.get('by') ?? 'invoice_number';
    const countryParam = params.get('country') ?? 'US';

    const [inputValue, setInputValue] = useState('');
    const [countryCode, setCountryCode] = useState(countryParam);
    const [trackInputLabel, setTrackInputLabel] = useState(t("track_input_by_tracking_number"));
    const [trackingData, setTrackingData] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [error, setError] = useState(null);
    const [searchBy, setSearchBy] = useState(byParam);

    useEffect(() => {
        if (byParam === 'invoice_number') {
            setTrackInputLabel(t("track_input_by_invoice_number"));
        } else if (byParam === 'phone_number') {
            setTrackInputLabel(t("track_input_by_phone_number"));
        } else {
            setTrackInputLabel(t("track_input_by_tracking_number"));
        }
    }, [byParam, t]);

    useEffect(() => {
        // This will force a re-render when the language changes
        i18n.on('languageChanged', () => {
            setTrackInputLabel(trackInputLabel);
        });
    }, [i18n, trackInputLabel]);

    useEffect(() => {
        if (Array.isArray(trackingData) && trackingData.length === 1) {
            // Found one tracking link, so we simply open it
            let trackingUrl = trackingData[0].url;
            let didWindowOpen = window.open(trackingUrl, '_blank');
            if (!didWindowOpen) {
                let a = document.createElement('a');
                a.target = '_blank';
                a.href = trackingUrl;
                a.click();
            }
        }
    }, [trackingData]);

    const resetForm = () => {
        setInputValue('');
        setTrackingData(null);
        setNotFound(false);
        setError(null);
    }

    const handleFormSubmit = () => {
        if (!(inputValue && byParam && orgParam)) {
            console.error('Error: One or many required values are empty.')
        } else {
            let requestBody = JSON.stringify({
                'search': inputValue,
                'by': searchBy,
                'country_code': countryCode,
                'org_id': typeof orgParam === 'string' ? orgParam.split(',') : orgParam,
                'loc_id': typeof locParam === 'string' ? locParam.split(',') : locParam
            });
            fetch(process.env.REACT_APP_API_PATH, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Language': getLocale()
                },
                body: requestBody
            })
                .then(response => response.json())
                .then(data => {
                    if ('data' in data && 'tracking_data' in data.data) {
                        setTrackingData(data.data.tracking_data);
                    } else {
                        console.error('Error: ', data.statusCode);
                        setNotFound(data.statusCode === 404);
                        setError(data);
                    }
                })
                .catch(error => {
                    console.error('Error: ', error);
                    setError(error);
                });
        }
    }

    const handleChangeSearchBy = (event) => {
        let by = event.target.value;
        setSearchBy(by);

        let inputLabel = t("track_input_by_tracking_number");
        if (by === 'invoice_number') {
            inputLabel = t("track_input_by_invoice_number");
        } else if (by === 'phone_number') {
            inputLabel = t("track_input_by_phone_number");
        }
        setTrackInputLabel(inputLabel);
        setInputValue('');
    }

    const handleChangeCountryCode = (event) => {
        setCountryCode(event.target.value);
    }

    const generate = element => trackingData.map((item, index) =>
        React.cloneElement(element, {
            key: index,
            children: (
                <>
                    <ListItemIcon style={{ color: theme.buttonBackgroundColor }}>
                        <RoomOutlinedIcon style={{
                            alignSelf: 'center',
                            textAlign: 'center',
                            width: '100%'
                        }}/>
                    </ListItemIcon>
                    <Link href={item.url} target="_blank" style={{ textDecoration: 'none' }}>
                        <ListItemText
                            style={{ color: theme.primaryTextColor }}
                            primary={item.type}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color={theme.secondaryTextColor}
                                    >
                                        {item.date}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </Link>
                </>
            )
        })
    );

    if (Array.isArray(trackingData) && trackingData.length >= 1) {
        return (
            <>
                <Grid container>
                    <Grid item xs={8}>
                        <List class="result-list">
                            {generate(
                                <ListItem xs={6} md={4} class="result-list-item"
                                          style={{
                                              borderColor: theme.buttonBackgroundColor,
                                          }}
                                />
                            )}
                        </List>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            id="search-again-btn"
                            variant="contained"
                            onClick={resetForm}
                            size="large"
                            style={{
                                backgroundColor: theme.buttonBackgroundColor,
                                color: theme.buttonTextColor,
                            }}
                        >
                            <ReplayOutlinedIcon/>
                        </Button>
                    </Grid>
                </Grid>
            </>
        );
    } else if (notFound) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        id="not-found-text"
                        fullWidth
                        elevation={0}
                        style={{ backgroundColor: theme.backgroundColor, color: theme.primaryTextColor }}
                    >
                        {t("no_tracking_links_found")}
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        id="not-found-btn"
                        variant="contained"
                        onClick={resetForm}
                        size="medium"
                        style={{ backgroundColor: theme.buttonBackgroundColor, color: theme.buttonTextColor }}
                    >
                        {t("start_over")} <ReplayOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        );
    } else if (error) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        id="not-found-text"
                        fullWidth
                        elevation={0}
                        style={{ backgroundColor: theme.backgroundColor, color: theme.primaryTextColor }}
                    >
                        {t("invalid_search_value")}
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        id="not-found-btn"
                        variant="contained"
                        onClick={resetForm}
                        size="medium"
                        style={{ backgroundColor: theme.buttonBackgroundColor, color: theme.buttonTextColor }}
                    >
                        {t("start_over")} <ReplayOutlinedIcon/>
                    </Button>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <>
                <ThemeProvider theme={customTextFieldTheme(outerTheme)}>
                    <Grid container spacing="5px">
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <FormControl fullWidth>
                                <InputLabel id="search-by-select-label">{t("search_by")}</InputLabel>
                                <Select
                                    labelId="search-by-select-label"
                                    id="search-by-select"
                                    sx={{
                                        backgroundColor: "white",
                                        borderColor: "#333",
                                        borderWidth: "1px"
                                    }}
                                    size="small"
                                    label={t("search_by")}
                                    value={searchBy}
                                    onChange={handleChangeSearchBy}
                                >
                                    <MenuItem value="invoice_number" dir={uiDirection}>{t("invoice_number")}</MenuItem>
                                    <MenuItem value="tracking_number" dir={uiDirection}>{t("tracking_number")}</MenuItem>
                                    <MenuItem value="phone_number" dir={uiDirection}>{t("phone_number")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={8} md={9} lg={10}>
                            <Grid container spacing="5px">
                                <Grid item xs={4} display={searchBy !== 'phone_number' ? "none" : "block"}>
                                    <FormControl fullWidth>
                                        <InputLabel id="phone-country-code-label">{t("country_code")}</InputLabel>
                                        <Select
                                            labelId="phone-country-code-label"
                                            id="country-code-select"
                                            sx={{
                                                backgroundColor: "white",
                                                borderColor: "#333",
                                                borderWidth: "1px"
                                            }}
                                            label={t("country_code")}
                                            size="small"
                                            value={countryCode}
                                            onChange={handleChangeCountryCode}
                                        >
                                            {Object.entries(countriesData).map(([code, details]) => (
                                                <MenuItem key={code} value={code}>{code} ({details.dial_code})</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={searchBy !== 'phone_number' ? 12 : 8}>
                                    <CustomTextField
                                        label={trackInputLabel}
                                        value={inputValue}
                                        onChange={newValue => setInputValue(newValue)}
                                        disabled={orgParam === null || orgParam === undefined}
                                        type={byParam}
                                        countryCode={countryCode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            id="track-btn"
                            variant="contained"
                            type="submit"
                            size="medium"
                            style={{ backgroundColor: theme.buttonBackgroundColor, color: theme.buttonTextColor }}
                            onClick={handleFormSubmit}
                            disabled={inputValue === ""}
                        >
                            {t("track_button")} {uiDirection === 'rtl' ? <ArrowBackIcon style={{marginLeft: "5px" }} /> : <ArrowForwardIcon style={{ marginLeft: "5px" }}/>}
                        </Button>
                    </Grid>
                </ThemeProvider>
            </>
        );
    }
}

export default TrackForm;