import TextField from '@mui/material/TextField';
import parsePhoneNumberFromString from 'libphonenumber-js';

const CustomTextField = ({ label, onChange, value, disabled, type }) => {
    const handleInputChange = (e) => {
        const inputValue = e.target.value;

        // Perform type-specific validation and formatting
        let formattedInputValue;
        switch (type) {
            case 'phone_number':
                formattedInputValue = formatPhoneNumber(inputValue);
                break;
            case 'tracking_number':
                formattedInputValue = inputValue.replace(/[^A-Za-z0-9 -]/g, '').substring(0, 30);
                break;
            case 'invoice_number':
                formattedInputValue = inputValue.substring(0, 60);
                break;
            default:
                formattedInputValue = inputValue;
        }

        // Call the provided onChange function with the formatted value
        onChange(formattedInputValue);
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Remove non-digit characters from the input
        const cleanedPhoneNumber = phoneNumber.replace(/[^0-9 +-\\)\\(]/g, '');
        const parsedPhoneNumber = parsePhoneNumberFromString(cleanedPhoneNumber);

        // Check if the phone number is valid and format it
        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
            return parsedPhoneNumber.formatNational();
        }
        return cleanedPhoneNumber;
    };

    return (
        <TextField
            fullWidth
            id="track-input"
            // variant="filled"
            sx={{
                input: {
                    background: "white"
                }
            }}
            size="small"
            label={label}
            value={value}
            onChange={handleInputChange}
            disabled={disabled}
        />
    );
};

export default CustomTextField;
